import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { getFirebase, actionTypes as rrfActionTypes } from 'react-redux-firebase';
import { constants as rfConstants, getFirestore } from 'redux-firestore';
import { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR } from '@components/Notifier/actions';

import rootReducer from '@config/rootReducer';

const sagaMiddleware = createSagaMiddleware();
const router = routerMiddleware(history);

const extraArgument = {
    getFirebase,
    getFirestore,
};

const middleware = [
    ...getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [
                // just ignore every redux-firebase and react-redux-firebase action type
                ...Object.keys(rfConstants.actionTypes).map(
                    (type) => `${rfConstants.actionsPrefix}/${type}`,
                ),
                ...Object.keys(rrfActionTypes).map((type) => `@@reactReduxFirebase/${type}`),
                ENQUEUE_SNACKBAR,
                CLOSE_SNACKBAR,
                REMOVE_SNACKBAR,
            ],
            ignoredPaths: ['firebase', 'firestore', 'notification.options.action'],
            ignoredActionPaths: ['notification.options.action'],
        },
        thunk: {
            extraArgument,
        },
    }),
];

const store = configureStore({
    reducer: rootReducer,
    middleware: [router, sagaMiddleware, ...middleware],
    devTools: process.env.NODE_ENV !== 'production' && module.hot,
});

// sagaMiddleware.run(authSaga);

export default store;
