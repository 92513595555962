export const ROOT = '/';
export const INVESTORS = '/investors';
export const SOURCING_AGENTS = '/sourcing-agents';
export const SOURCING_AGENTS_DASHBOARD = '/sourcing-agents-dashboard';
export const ABOUT = '/about';
export const ADD_LISTING = '/add-listing';
export const EDIT_LISTING = '/edit-listing/:listingId';
export const CONTACT = '/contact';
export const LOGIN = '/login';
export const REGISTER_AGENT = '/register_agent';
export const REGISTER_INVESTOR = '/register_investor';
export const REGISTER_MOBILE = '/register';
export const DASHBOARD = '/dashboard';
export const MYDEALS = '/mydeals';
export const PRICING = '/pricing';
export const VERIFY_EMAIL = '/verify-email';
export const DEAL_DETAILS = '/deal/:dealId';
export const ALERTS = '/alerts';
export const WATCHLIST = '/watchlists';
export const SUCCESSFULRESERVATION = '/successful/:dealId';
export const SUCCESSFULSUBSCRIPTION = '/successful_subscription/:userId';
export const FAILEDRESERVATION = '/failed/:dealId';
export const PROFILE = '/profile';
export const MY_DOCUMENTS = '/mydocuments';
export const AGENT_DOCUMENTS = '/agentdocuments';
export const CHECKOUT = '/checkout';
export const ADMIN_INVESTORS = '/admin-investors';
export const ADMIN_AGENTS = '/admin-sourcing-agents';
export const ADMIN_LISTINGS = '/admin-listings';
