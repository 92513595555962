import { withStyles } from '@material-ui/core';

const styles = () => ({
    '@global': {
        [`*,
      :after,
      :before`]: {
            [`-webkit-box-sizing`]: 'border-box',
            boxSizing: 'border-box',
            textRendering: 'optimizeLegibility',
            [`font-variant-ligatures`]: 'none',
            [`-webkit-font-variant-ligatures`]: 'none',
            [`-webkit-font-smoothing`]: 'antialiased',
            [`-moz-osx-font-smoothing`]: 'grayscale',
        },
        body: {
            fontFamily: `'Poppins', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`,
        },
    },
});

function globalStyles() {
    return null;
}

export default withStyles(styles, { withTheme: true })(globalStyles);
