import './index.scss';
import GlobalStyles from '@config/GlobalStyles';
import history from '@config/history';
import initFirebase from '@config/initFirebase';
import store from '@config/store';
import theme from '@config/theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/analytics';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import App from './App';
import reportWebVitals from './reportWebVitals';

initFirebase();

const rrfProps = {
    firebase,
    config: {
        autoPopulateProfile: true,
        userProfile: 'users',
        useFirestoreForProfile: true,
        attachAuthIsReady: true,
    },
    dispatch: store.dispatch,
    createFirestoreInstance,
};
export const storage = firebase.storage();

ReactDOM.render(
    <>
        <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider
                        preventDuplicate
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}>
                        <ConnectedRouter history={history}>
                            <CssBaseline />
                            <GlobalStyles />
                            <App />
                        </ConnectedRouter>
                    </SnackbarProvider>
                </ThemeProvider>
            </ReactReduxFirebaseProvider>
        </Provider>
    </>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
