export var config = {
    apiKey: 'AIzaSyDYR7Y3_Cf4G_z-esRIDosiWucBKgC9goI',
    authDomain: 'dealprop-db86f.firebaseapp.com',
    databaseURL: 'https://dealprop-db86f.firebaseio.com',
    projectId: 'dealprop-db86f',
    storageBucket: 'dealprop-db86f.appspot.com',
    messagingSenderId: '53025145571',
    appId: '1:53025145571:web:c254f20b6f3248f8d03bd6',
    measurementId: 'G-FTFHDEWMB0',
};
