import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '100vh',
    },
}));

function AppLoadingSpinner({ size }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress mode="indeterminate" size={size || 40} />
        </div>
    );
}

AppLoadingSpinner.propTypes = {
    size: PropTypes.number,
};

export default AppLoadingSpinner;
