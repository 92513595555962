import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { firebaseReducer } from 'react-redux-firebase';
import { reducer as form } from 'redux-form';
import { firestoreReducer } from 'redux-firestore';

import { reducer as notifications } from '@components/Notifier';
import history from '@config/history';

const rootReducer = combineReducers({
    router: connectRouter(history),
    form,
    notifications,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
});

export default rootReducer;
