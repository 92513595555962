import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore'; // make sure you add this for firestore
import { config } from './fbConfig';

let firebaseInstance;

export default function initFirebase() {
    if (firebaseInstance) {
        return firebaseInstance;
    }

    // Initialize firebase instance if it doesn't already exist
    if (!firebaseInstance) {
        firebase.initializeApp(config);
        firebase.firestore().settings({ timestampsInSnapshots: true });
        firebaseInstance = firebase;
    }

    return firebaseInstance;
}
