import Routes from './Routes';
import React from 'react';
import Notifier from '@components/Notifier';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
Sentry.init({
    dsn: 'https://86d56c8632904bdeb19273cd2914d1ca@o1156126.ingest.sentry.io/6237195',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
});
function App() {
    return (
        <>
            <Routes />
            <Notifier />
        </>
    );
}

export default App;
